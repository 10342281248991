import React, { useEffect, useState } from 'react';
import './EmailVerification.css';

const EmailVerification = () => {
    const [isVerified, setIsVerified] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const minimumLoadingTime = 2000; // 2 seconds

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const email = urlParams.get('email');

        if (token && email) {
            fetch(`https://www.arcoempires.com/api/verify?token=${token}&email=${email}`)
                .then(response => response.json())
                .then(data => {
                    if (data.message === 'Email verified successfully') {
                        setTimeout(() => {
                            setIsVerified(true);
                            setIsLoading(false);
                        }, minimumLoadingTime);
                    } else {
                        setTimeout(() => {
                            setIsLoading(false);
                            setErrorMessage(data.message || 'Verification failed');
                        }, minimumLoadingTime);
                    }
                })
                .catch(error => {
                    setTimeout(() => {
                        setIsLoading(false);
                        setErrorMessage(error.message || 'Network error during verification');
                    }, minimumLoadingTime);
                });
        } else {
            setTimeout(() => {
                setIsLoading(false);
                setErrorMessage('Token and email are required for verification');
            }, minimumLoadingTime);
        }
    }, []);

    const handleDeepLinkRedirect = () => {
        window.location.href = 'arco://emailverify/success';
    };

    return (
        <div className="verification-container">
            {isLoading ? (
                <>
                    <div className="verifying-text">Verifying...</div>
                    <div className="loader"></div>
                </>
            ) : isVerified ? (
                <>
                    <div className="email-success-message">
                        <div className="checkmark">&#10004;</div>
                        SUCCESS!
                    </div>
                    <button onClick={handleDeepLinkRedirect} className="early-access-button">Continue Here</button>
                </>
            ) : (
                <div className="error-message">{errorMessage}</div>
            )}
        </div>
    );
};

export default EmailVerification;
