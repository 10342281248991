import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import AppHeader from './components/Header/AppHeader';
import AppFooter from './components/Footer/AppFooter';
import ComingSoon from './components/ComingSoon/ComingSoon';
import EmailVerification from './components/EmailVerification/EmailVerification';
import Create from './Create/components/Create';
import Marketplace from './MarketPlace/components/Marketplace';

function MainApp() {
  const location = useLocation();

  // Determine if the current location is ComingSoon or EmailVerification
  const isSpecialPage = location.pathname === '/' || location.pathname === '/email-verification';

  return (
    <div className="App">
      <AppHeader isComingSoon={isSpecialPage} />
      <div className="content">
        <Routes>
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/create" element={<Create />} />
          <Route path="/home" element={<ComingSoon />} />
          <Route path="/" element={<ComingSoon />} />
          <Route path="/email-verification" element={<EmailVerification />} />
          <Route path="*" element={<ComingSoon />} />
        </Routes>
      </div>
      <AppFooter />
    </div>
  );
}

function App() {
  return (
    <Router>
      <MainApp />
    </Router>
  );
}

export default App;
