import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Marketplace.css'; // You can create a separate CSS file for styling the Marketplace component

const Marketplace = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Redirect to the Coming Soon page
        navigate('/');
    }, [navigate]);

    return (
        <div className="marketplace">
            <h1>Welcome to the Marketplace</h1>
            {/* Other components and content related to the marketplace can be added here */}
        </div>
    );
};

export default Marketplace;
