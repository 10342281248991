import React, { useState } from 'react';
import axios from 'axios';
import './ComingSoon.css';
import arcoWalletLogo from './arcoWalletLogo.svg';
import QREarlyAccess from '../QRCode/QREarlyAccess';

const ComingSoon = () => {
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [message, setMessage] = useState('');
    const [isQREarlyAccessModalOpen, setIsQREarlyAccessModalOpen] = useState(false);

    const emailRegex = /\S+@\S+\.\S+/;

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);
        setIsValidEmail(emailRegex.test(emailValue));
    };

    const handleSubscription = async (e) => {
        e.preventDefault();
        if (!isValidEmail) return;

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/subscribe`, { email });
            setMessage(response.data ? 'Subscribed successfully!' : 'Failed to subscribe. Please try again later.');
        } catch (error) {
            console.error('Subscription error:', error);
            setMessage(
                error.response && error.response.data && error.response.data.includes('already a list member')
                ? "You're Good to Go! Your Email is Already on the List!"
                : 'Failed to subscribe. Please try again later.'
            );
        }
    };

    const handleEarlyAccessClick = () => {
        setIsQREarlyAccessModalOpen(true);
    };

    return (
        <div className="coming-soon-container">
            <div className="heading-form-container">
                <h1>We're Still Building...</h1>
                <p>LAUNCH DATE: When We're Done</p>
                <form onSubmit={handleSubscription} className="subscription-form">
                    <label htmlFor="email" className="email-label">BE THE FIRST TO KNOW</label>
                    <input 
                        type="email" 
                        id="email" 
                        className={`email-input ${isValidEmail ? 'valid' : ''}`}
                        value={email} 
                        onChange={handleEmailChange} 
                        required 
                        placeholder="Enter email"
                    />
                    <button type="submit" className={`subscribe-button ${isValidEmail ? '' : 'disabled'}`} disabled={!isValidEmail}>Subscribe</button>
                </form>
            </div>

            {message && <p className="message">{message}</p>}

            <div className="tile-container">
                <div className="early-access-tile">
                    <img src={arcoWalletLogo} alt="Arco Wallet Logo" className="arco-wallet-logo" />
                    <button onClick={handleEarlyAccessClick} className="early-access-button">
                        Early Access
                    </button>
                    <p className="subtext">For the Privileged Few</p>
                </div>
            </div>

            <QREarlyAccess 
                isOpen={isQREarlyAccessModalOpen} 
                onRequestClose={() => setIsQREarlyAccessModalOpen(false)}
            />
        </div>
    );
};

export default ComingSoon;
