import React from 'react';
import './AppFooter.css';
import arcoFooterLogo from '../../assets/LogoNoText.svg'; // Update the path as necessary
import { ReactComponent as TwitterIcon } from '../../assets/social/x-twitter.svg'; // Ensure correct file name
import { ReactComponent as FacebookIcon } from '../../assets/social/facebook.svg'; // Ensure correct file name
import { ReactComponent as YoutubeIcon } from '../../assets/social/youtube.svg'; // Ensure correct file name

const AppFooter = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="app-footer">
            <div className="social-icons">
                <a href="https://twitter.com/ArcoEmpires" target="_blank" rel="noopener noreferrer">
                    <TwitterIcon className="icon" />
                </a>
                <a href="https://www.facebook.com/arcoempires" target="_blank" rel="noopener noreferrer">
                    <FacebookIcon className="icon" />
                </a>
                <a href="https://www.youtube.com/channel/UCt1Zqlx29lTdI1d85RkWjUA" target="_blank" rel="noopener noreferrer">
                    <YoutubeIcon className="icon" />
                </a>
            </div>
            <img src={arcoFooterLogo} alt="Arco Footer Logo" className="footer-logo" />
            <div className="copyright">
                © {currentYear} Arco Empires Inc., all rights reserved.
            </div>
        </footer>
    );
};

export default AppFooter;
