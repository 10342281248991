import React, { useState, useRef, useEffect } from 'react';
import './CreateNFT.css'; 
import Dropzone from 'react-dropzone';
import WalletConnectModal from '../../components/WalletConnectModal/WalletConnectModal'; // Import the new modal component

const CreateNFT = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [collection, setCollection] = useState('');
    const [supply, setSupply] = useState('');
    const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);  // Added this state to control the visibility of the wallet connect modal
    
    const dropzoneRef = useRef(null);

    const updateDropzoneHeight = () => {
        if (dropzoneRef.current) {
            const width = dropzoneRef.current.getBoundingClientRect().width;
            dropzoneRef.current.style.height = `${width}px`;
        }
    };

    useEffect(() => {
        updateDropzoneHeight();
        window.addEventListener('resize', updateDropzoneHeight);
        return () => window.removeEventListener('resize', updateDropzoneHeight);
    }, []);

    const onDrop = (acceptedFiles) => {
        setFile(acceptedFiles[0]);
        setPreview(URL.createObjectURL(acceptedFiles[0]));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsWalletModalOpen(true);  // Open the wallet connect modal when the "Create NFT" button is clicked
    };

    const closeWalletModal = () => {
        setIsWalletModalOpen(false);  // Function to close the modal
    };

    return (
        <div className="create-nft-container">
            <WalletConnectModal 
                isOpen={isWalletModalOpen} 
                onRequestClose={closeWalletModal}  // Added the modal with props to control its visibility
            />
            <div className="nft-form-container">
                <div className="nft-left-section">
                    <h2>Create Your NFT</h2>
                    <Dropzone onDrop={onDrop}>
                        {({getRootProps, getInputProps}) => (
                            <section>
                                <div {...getRootProps()} className="nft-dropzone" ref={dropzoneRef}>
                                    <input {...getInputProps()} />
                                    {preview ? (
                                        <img src={preview} alt="Preview" style={{width: '100%', height: '100%', objectFit: 'cover'}} />
                                    ) : (
                                        <p>Drag & drop a file here, or click to select one</p>
                                    )}
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>
                <div className="nft-right-section">
                    <label className="nft-label">
                        <span className="nft-label-text">Collection</span>
                        <input 
                            className="nft-input" 
                            type="text" 
                            value={collection} 
                            onChange={(e) => setCollection(e.target.value)}
                        />
                    </label>

                    <label className="nft-label">
                        <span className="nft-label-text">Name</span>
                        <input 
                            className="nft-input"
                            type="text" 
                            value={title} 
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </label>

                    <label className="nft-label">
                        <span className="nft-label-text">Supply</span>
                        <input 
                            className="nft-input"
                            type="number" 
                            value={supply} 
                            onChange={(e) => setSupply(e.target.value)}
                        />
                    </label>

                    <label className="nft-label">
                        <span className="nft-label-text">Description</span>
                        <textarea 
                            className="nft-input nft-textarea"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </label>
                </div>
            </div>
            <button 
                onClick={handleSubmit} 
                className="nft-styled-button"
            >
                Create NFT
            </button>
        </div>
    );
};

export default CreateNFT;
