import React from 'react';
import Modal from 'react-modal';
import './WalletConnectModal.css'; 
import IconNtxt from './IconNtxt.svg';

Modal.setAppElement('#root');

const WalletConnectModal = ({ isOpen, onRequestClose, onWalletConnect }) => {
    const handleWalletConnect = () => {
        onWalletConnect();
        onRequestClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Wallet Connection"
            className="wallet-connect-modal"
            overlayClassName="overlay"
        >
            <h2>Connect Your Wallet</h2>
            <p>
                Before you Create your First Digital Asset, you'll need to connect your wallet. Click the icon below to get started.
            </p>
            <hr />
            <div className="wallet-option" onClick={handleWalletConnect}>
                <img src={IconNtxt} alt="Arco Wallet" className="wallet-icon" />
            </div>
        </Modal>
    );
};

export default WalletConnectModal;
