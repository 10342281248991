import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateNFT from './CreateNFT'; // Import the CreateNFT component

const Create = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Temporary redirect logic
        navigate('/');
    }, [navigate]);

    return (
        <div className="create-page">
            <CreateNFT /> {/* Render the CreateNFT component */}
        </div>
    );
};

export default Create;
