import React, { useState, useEffect } from 'react';
import './AppHeader.css';
import logo from '../../assets/logo.svg';
import { FaShoppingCart, FaWallet, FaUserCircle, FaBars, FaTimes, FaArrowRight, FaStore, FaPlusCircle } from 'react-icons/fa';
import WalletConnectModal from '../WalletConnectModal/WalletConnectModal';

const AppHeader = ({ isComingSoon }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleMenuToggle = () => setIsMenuOpen(!isMenuOpen);
    const closeMobileMenu = () => setIsMenuOpen(false);
    const openModal = () => {
        console.log('Opening Modal');
        setIsModalOpen(true);
    };
    const closeModal = () => {
        console.log('Closing Modal');
        setIsModalOpen(false);
    };

    return (
        <div className="header">
            <div className="left-section">
                <div className="logo">
                    <a href="/" style={{ display: 'inline-block' }}>
                        <img src={logo} alt="Game Logo" />
                    </a>
                </div>
                {!isComingSoon && !isMobile && (
                    <nav>
                        <a href="/marketplace" className="nav-link">Marketplace</a>
                        <a href="#" onClick={openModal} className="nav-link">Create</a>
                    </nav>
                )}
            </div>
            {!isComingSoon && (
                <div className="right-section">
                    <div className="wallet-info">
                        <FaWallet className="icon" />
                        {!isMobile && <span>100 ARCs</span>}
                    </div>
                    <div className="icons">
                        <FaShoppingCart className="icon" />
                        {isMobile ? 
                            (isMenuOpen ? 
                                <FaTimes className="icon menu-icon" onClick={handleMenuToggle} />
                                : 
                                <FaBars className="icon menu-icon" onClick={handleMenuToggle} />
                            ) 
                            : 
                            <FaUserCircle className="icon" />
                        }
                    </div>
                </div>
            )}
            {isMobile && !isComingSoon && isMenuOpen && (
                <div className="mobile-menu open">
                    <a href="/user" className="nav-link" onClick={closeMobileMenu}>
                        <FaUserCircle className="menu-icon" /> User Account <FaArrowRight className="close-icon" />
                    </a>
                    <a href="/marketplace" className="nav-link" onClick={closeMobileMenu}>
                        <FaStore className="menu-icon" /> Marketplace <FaArrowRight className="close-icon" />
                    </a>
                    <a href="#" onClick={() => {closeMobileMenu(); openModal();}} className="nav-link">
                        <FaPlusCircle className="menu-icon" /> Create <FaArrowRight className="close-icon" />
                    </a>
                </div>
            )}
            <WalletConnectModal isOpen={isModalOpen} onRequestClose={closeModal} />
        </div>
    );
};

export default AppHeader;
