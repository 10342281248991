import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';  // Assuming you're using this library for QR code generation
import './QREarlyAccess.css';

const QREarlyAccess = ({ isOpen, onRequestClose }) => {
    const [qrData, setQRData] = useState('');
    const [error, setError] = useState(null);

    useEffect(() => {
        if (isOpen) {
            fetch(`${process.env.REACT_APP_API_URL}/api/generate-qr-data`)
                .then(response => response.json())
                .then(data => {
                    setQRData(JSON.stringify(data));
                })
                .catch(err => {
                    setError('Error generating QR code. Please try again later.');
                    console.error(err);
                });
        }
    }, [isOpen]);

    return (
        <div className={`qr-early-access-modal ${isOpen ? 'is-open' : ''}`}>
            <div className="modal-content">
                {error ? (
                    <p className="error">{error}</p>
                ) : (
                    // Add a class to the QRCode element
                    <div className="qr-code-container">
                        <QRCode value={qrData} size={256} />
                    </div>
                )}
                <button onClick={onRequestClose}>Close</button>
            </div>
        </div>
    );
};

export default QREarlyAccess;
